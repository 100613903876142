import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Main from './Main';
import { useNavigate } from 'react-router-dom';

export default function AddUser() {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = sessionStorage.getItem("token");
    const [status, setStatus] = useState("");
    const username_ref = useRef(null);
    const password_ref = useRef(null);
    const password2_ref = useRef(null);
    const navigate = useNavigate();
    useEffect(() => {

    },
        []);

    const handleSubmitForm = async () => {
        if (username_ref.current.value == "") {
            alert("Please input username!");
            return;
        }
        if (password_ref.current.value == "" || password2_ref.current.value == "") {
            alert("Please input password!");
            return;
        }
        if (password_ref.current.value !== password2_ref.current.value) {
            alert("Password mismatch!")
            return;
        }
        const new_user = {
            username: username_ref.current.value ? username_ref.current.value : null,
            password: password_ref.current.value ? password_ref.current.value : null,
        }
        const formData = new FormData();

        formData.append('headers', { Authorization: `Bearer ${token}` })
        formData.append('new_user', JSON.stringify(new_user));
        await axios.post(baseURL + "/add_user", formData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => { //console.log(response.data)
            if (response.data[0]["status"] == "success")
                setStatus("success")
            if (response.data[0]["status"] == "error")
                setStatus("error")
            if (response.data[0]["status"] == "error2")
                setStatus("error2")
            //console.log(response.data[0]);
        })
            .catch(error => {
                console.log('Cannot add new user', error);
                setStatus("error")
            })
    }
    let status_detail
    if (status == "success")
        status_detail =
            <div className="ml-5 border border-green-400 bg-green-50 rounded-md drop-shadow-md">
                <p className="p-6 text-lg font-medium text-sky-800 text-center">
                    <ion-icon name="checkmark-circle-outline" style={{ 'color': '#8de02c', 'font-size': '50px' }} className="text-center"></ion-icon><br />
                    Add user successfully!<br />
                    <button type="submit" onClick={() => { setStatus(""); navigate(`/users`) }}
                        className="mt-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Go back
                    </button>
                </p>

            </div>
    else if (status == "error")
        status_detail =
            <div className="ml-5 border border-red-400 bg-red-50 rounded-md drop-shadow-md">
                <p className="p-6 text-lg font-medium text-red-800 text-center">
                    <ion-icon name="alert-circle-outline" style={{ 'color': '#f00', 'font-size': '50px' }} className="text-center"></ion-icon><br />
                    Error, cannot add user!<br />
                    <button type="submit" onClick={() => setStatus("")}
                        className="mt-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Retry again?
                    </button>
                </p>
            </div>
    else if (status == "error2")
        status_detail =
            <div className="ml-5 border border-red-400 bg-red-50 rounded-md drop-shadow-md">
                <p className="p-6 text-lg font-medium text-red-800 text-center">
                    <ion-icon name="alert-circle-outline" style={{ 'color': '#f00', 'font-size': '50px' }} className="text-center"></ion-icon><br />
                    Error, user alread existed!<br />
                    <button type="submit" onClick={() => setStatus("")}
                        className="mt-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Retry again?
                    </button>
                </p>
            </div>
    return (<Main menuText={"Users"}>
        {status_detail}
        {status == "" ?
            <form className="ml-5">
                <center className="text-2xl font-bold">Add new user</center>
                <fieldset className="rounded-md border border-gray-300 drop-shadow-md">
                    <legend className="ml-4 text-xl font-semibold leading-7 text-gray-900">Username & Password</legend>
                    <div className="mt-4 ml-4">
                        <label htmlFor="username">Username :</label>
                        <input type="text" id="username" name="username" ref={username_ref} placeholder="Type user name..." className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" />
                    </div>
                    <div className="mt-4 ml-4">
                        <label htmlFor="password">Password :</label>
                        <input type="password" id="password" name="password" ref={password_ref} placeholder="Type password..." className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" />
                    </div>
                    <div className="mt-4 ml-4">
                        <label htmlFor="password2">Retype password :</label>
                        <input type="password" id="password2" name="password2" ref={password2_ref} placeholder="Retype password..." className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" />
                    </div>
                    <div className="ml-4 mb-6 mt-6 flex items-center justify-left gap-x-6">
                        <button type="reset" className="rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 drop-shadow-md">
                            Reset
                        </button>
                        <button onClick={() => navigate("/users")}
                            type="button"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 drop-shadow-md"
                        >
                            Cancel
                        </button>
                        <button onClick={handleSubmitForm}
                            type="button"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 drop-shadow-md"
                        >
                            Add user
                        </button>
                    </div>
                </fieldset>
            </form> : <></>
        }
    </Main>);
}