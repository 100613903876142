import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Main from './Main';
import { useNavigate } from 'react-router-dom';

export default function UserPage() {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = sessionStorage.getItem("token");
    const [users, setUsers] = useState([]);
    const [status, setStatus] = useState("");
    const navigate = useNavigate();
    const deleteUser = async (_id, _user) => {
        if (_id == '1') {
            alert('Cannot delete admin account!');
            return;
        }
        if (window.confirm(`Are you sure to delete this user ${_user}?`)) {
            await axios.delete(`${baseURL}/delete_user/${_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(response => {
                if (response.data["status"] == "success")
                    setStatus("success2")
                if (response.data["status"] == "error")
                    setStatus("error2")
            }).catch(error => {
                console.log('Cannot delete user', error);
                setStatus("error2")
            });
        }

    }
    useEffect(() => {
        axios.get(`${baseURL}/users`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(result => {
            setUsers(result.data);
            //console.log(result.data);
        }).catch(error => {
            console.log(error);
        });
    }, [status]);
    let status_detail;
    if (status == "success2")
        status_detail =
            <div className="ml-5 border border-green-400 bg-green-50 rounded-md drop-shadow-md">
                <p className="p-6 text-lg font-medium text-sky-800 text-center">
                    <ion-icon name="checkmark-circle-outline" style={{ 'color': '#8de02c', 'font-size': '50px' }} className="text-center"></ion-icon><br />
                    Delete user successfully!<br />
                    <button type="submit" onClick={() => { setStatus(""); navigate(`/users`) }}
                        className="mt-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Back to User Page
                    </button>
                </p>

            </div>
    else if (status == "error2")
        status_detail =
            <div className="ml-5 border border-red-400 bg-red-50 rounded-md drop-shadow-md">
                <p className="p-6 text-lg font-medium text-red-800 text-center">
                    <ion-icon name="alert-circle-outline" style={{ 'color': '#f00', 'font-size': '50px' }} className="text-center"></ion-icon><br />
                    Error, cannot delete machine!<br />
                    <button type="submit" onClick={() => { setStatus(""); navigate(`/users`) }}
                        className="mt-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Go back
                    </button>
                </p>
            </div>

    const userLists = users.map(u => <div key={u.id} id='users'>
        <ion-icon name="person"></ion-icon>
        <br />{u.username}
        <br />
        <span className="mr-5"><ion-icon name="create" onClick={() => {navigate('/users/edit/'+u.id); }}></ion-icon></span>
        <ion-icon name="trash" onClick={() => { deleteUser(u.id, u.username) }}></ion-icon>
        <br />
    </div>)
    return (<Main menuText={"Users"}>
        {status_detail}
        {status == "" ?
            <>  <button type="submit" onClick={() => navigate('/users/add')}
                className="ml-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <ion-icon name="person-add-outline"></ion-icon> Add user
            </button>{userLists}</> : <></>
        }
    </Main>);
}