
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Main from "./Main";
import Modal from 'react-modal';
import Dropzone from 'react-dropzone';

export default function EditMachine() {
    const { machine_id } = useParams();

    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = sessionStorage.getItem("token");

    const [machineInfo, setMachineInfo] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);
    const [clearImage, setClearImage] = useState(false);
    const [sensor, setSensor] = useState([]);
    const [attachSensor, setAttachSensor] = useState([]);

    const navigate = useNavigate();

    const machine_id_ref = useRef(null);
    const machine_name_ref = useRef(null);
    const machine_type_ref = useRef(null);
    const site_name_ref = useRef(null);
    const process_name_ref = useRef(null);

    const [status, setStatus] = useState("");
    const onUpdateMachineClick = async () => {
        const update_machine = {
            machine_id: machine_id ? machine_id : null,
            machine_name: machine_name_ref.current.value ? machine_name_ref.current.value : null,
            machine_type: machine_type_ref.current.value ? machine_type_ref.current.value : null,
            site_name: site_name_ref.current.value ? site_name_ref.current.value : null,
            process_name: process_name_ref.current.value ? process_name_ref.current.value : null,
            old_image_name: machineInfo.image_name ? machineInfo.image_name : null,
            clear_image: clearImage,
            attached_sensor: attachSensor,
        }
        const formData = new FormData();
        formData.append('update_machine', JSON.stringify(update_machine));
        formData.append('file', selectedFile);

        if (update_machine.machine_name === "")
            alert("Please fill in machine name!")
        else {
            //alert(JSON.stringify(new_sensor))
            await axios.put(`${baseURL}/update_machine/${machine_id}`, formData,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => { //console.log(response.data)
                    if (response.data[0]["status"] == "success")
                        setStatus("success")
                    if (response.data[0]["status"] == "error")
                        setStatus("error")
                })
                .catch(error => {
                    console.log('Cannot update sensor', error);
                    setStatus("error")
                });
        }
    }
    const onDeleteMachineClick = async () => {
        if (window.confirm(`Are you sure to delete this machine ${machineInfo.machine_name}?`)) {
            await axios.delete(`${baseURL}/delete_machine/${machine_id}`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => { //console.log(response.data)
                    if (response.data["status"] == "success")
                        setStatus("success2")
                    if (response.data["status"] == "error")
                        setStatus("error2")

                })
                .catch(error => {
                    console.log('Cannot delete machine', error);
                    setStatus("error2")
                });
        }
    }

    let status_detail
    if (status == "success")
        status_detail =
            <div className="ml-5 border border-green-400 bg-green-50 rounded-md drop-shadow-md">
                <p className="p-6 text-lg font-medium text-sky-800 text-center">
                    <ion-icon name="checkmark-circle-outline" style={{ 'color': '#8de02c', 'fontSize': '50px' }} className="text-center"></ion-icon><br />
                    Update machine successfully!<br />
                    <button type="submit" onClick={() => { setStatus(""); navigate(`/machines/`+machine_id) }}
                        className="mt-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Go back
                    </button>
                </p>

            </div>
    else if (status == "error")
        status_detail =
            <div className="ml-5 border border-red-400 bg-red-50 rounded-md drop-shadow-md">
                <p className="p-6 text-lg font-medium text-red-800 text-center">
                    <ion-icon name="alert-circle-outline" style={{ 'color': '#f00', 'font-size': '50px' }} className="text-center"></ion-icon><br />
                    Error, cannot update machine!<br />
                    <button type="submit" onClick={() => setStatus("")}
                        className="mt-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Retry again?
                    </button>
                </p>
            </div>
    else if (status == "success2")
        status_detail =
            <div className="ml-5 border border-green-400 bg-green-50 rounded-md drop-shadow-md">
                <p className="p-6 text-lg font-medium text-sky-800 text-center">
                    <ion-icon name="checkmark-circle-outline" style={{ 'color': '#8de02c', 'font-size': '50px' }} className="text-center"></ion-icon><br />
                    Delete machine successfully!<br />
                    <button type="submit" onClick={() => { navigate(`/machines`) }}
                        className="mt-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Back to Machine Page
                    </button>
                </p>

            </div>
    else if (status == "error2")
        status_detail =
            <div className="ml-5 border border-red-400 bg-red-50 rounded-md drop-shadow-md">
                <p className="p-6 text-lg font-medium text-red-800 text-center">
                    <ion-icon name="alert-circle-outline" style={{ 'color': '#f00', 'font-size': '50px' }} className="text-center"></ion-icon><br />
                    Error, cannot delete machine!<br />
                    <button type="submit" onClick={() => { setStatus(""); navigate(`/machines/edit/${machine_id}`) }}
                        className="mt-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Go back
                    </button>
                </p>
            </div>
    else
        status_detail = <></>

    useEffect(() => {
        axios(`${baseURL}/machines/${machine_id}`,{
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                //console.log(response.data)
                setMachineInfo(response.data);
                if (document.getElementById("machine_name"))
                    document.getElementById("machine_name").focus()
            });
        axios.get(baseURL + "/sensors/unattached",{
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).
            then((response) => {
                //console.log(response.data)
                setSensor(response.data.results);
            });
        axios(`${baseURL}/sensors/attached/${machine_id}`,{
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                //console.log(response.data)
                setAttachSensor(response.data);
            })

    }, [])
    const sensorList = sensor.map(s => <option value={s.sensor_id} key={s.sensor_id} />);
    const attachSensorList = attachSensor.map(s => <span key={s} id='attachsensor_element'>{s}<ion-icon onClick={() => {
        setAttachSensor(attachSensor.filter(_s => _s !== s));
        setSensor([...sensor, { sensor_id: s }]);
    }} style={{ marginLeft: '5px', verticalAlign: 'sub', fontSize: '20px', color: 'red' }} name="close-circle"></ion-icon></span>);
    return (<Main menuText={"Machines"}>
        {status_detail}
        {status == "" ?
            <form className="ml-5">
                <center className="text-2xl font-bold">Edit machine</center>
                <fieldset className="rounded-md border border-gray-300 drop-shadow-md">
                    <legend className="ml-4 text-xl font-semibold leading-7 text-gray-900">Machine Information</legend>
                    <div className="mt-4 ml-4">
                        <label htmlFor="machine_name">Machine name :</label>
                        <input type="text" id="machine_name" name="machine_name" ref={machine_name_ref} defaultValue={machineInfo.machine_name} className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" />
                    </div>
                    <div className="mt-4 ml-4">
                        <label htmlFor="machine_type">Machine type :</label>
                        <input type="text" id="machine_type" name="machine_type" ref={machine_type_ref} defaultValue={machineInfo.machine_type} className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" />
                    </div>
                    <div className="mt-4 ml-4">
                        <label htmlFor="process_name">Process name :</label>
                        <input type="text" id="process_name" name="process_name" ref={process_name_ref} defaultValue={machineInfo.process_name} className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" />
                    </div>
                    <div className="mt-4 ml-4">
                        <label htmlFor="site_name">Site name :</label>
                        <input type="text" id="site_name" name="site_name" ref={site_name_ref} defaultValue={machineInfo.site_name} className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" />
                    </div>
                    <div className="mt-4 ml-4 mb-4" id="Dropzone">
                        <center><b>Drag & Drop a machine image here, or click to select an image file</b></center>
                        <Dropzone onDrop={acceptedFiles => {
                            setSelectedFile(acceptedFiles[0]);
                            setClearImage(false);
                        }}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    Machine Image:
                                    {selectedFile ?
                                        <>
                                            <img
                                                alt="Dropped image"
                                                className="h-64"
                                                src={URL.createObjectURL(selectedFile)}
                                            />
                                            {selectedFile.name}<ion-icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); setSelectedFile(null); setClearImage(true) }} style={{ marginLeft: '5px', verticalAlign: 'sub', fontSize: '20px', color: 'red' }} name="close-circle"></ion-icon>
                                        </> : <></>
                                    }
                                    {machineInfo.image_name && selectedFile == null && clearImage == false ? <>
                                        <img
                                            alt="Dropped image"
                                            className="h-64"
                                            src={baseURL + '/images/' + machine_id + '_' + machineInfo.image_name + '.jpg'}
                                        />
                                        {machineInfo.image_name}<ion-icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); setSelectedFile(null); setClearImage(true) }} style={{ marginLeft: '5px', verticalAlign: 'sub', fontSize: '20px', color: 'red' }} name="close-circle"></ion-icon></> : <></>
                                    }

                                </div>
                            )}
                        </Dropzone>
                    </div>
                    <label htmlFor="sensorlists" className="ml-4 mt-4">Choose sensors to attach from the list : </label>
                    <input list="unattached_sensors" name="sensorlists" id="sensorlists" className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" />
                    <datalist id="unattached_sensors">
                        {sensorList}
                    </datalist>
                    <button onClick={(e) => {

                        let _attachSensor = document.getElementById("sensorlists").value;

                        if (_attachSensor != "" && sensor.filter(s => s.sensor_id == _attachSensor).length > 0) {

                            setAttachSensor(Array.from(new Set([...attachSensor, _attachSensor])));
                            setSensor(sensor.filter(_s => _s.sensor_id !== _attachSensor));
                            document.getElementById("sensorlists").value="";
                            //console.log(attachSensor);
                        }
                        if (_attachSensor == "") alert("Please fill sensor id!");
                        else if (sensor.filter(s => s.sensor_id == _attachSensor).length == 0) alert("Cannot attach this sensor!");
                        e.preventDefault();

                    }} className="ml-4 rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 drop-shadow-md">
                        attach sensor</button>
                    <br /><div className="ml-4 mt-4">Attached Sensors : {attachSensorList}</div>
                    <div className="ml-4 mb-6 mt-6 flex items-center justify-left gap-x-6">
                        <button type="reset" onClick={() => { setClearImage(false) }} className="rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 drop-shadow-md">
                            Reset
                        </button>
                        <button onClick={() => navigate("/machines/"+machine_id)}
                            type="button"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 drop-shadow-md"
                        >
                            Cancel
                        </button>
                        <button onClick={() => onUpdateMachineClick()}
                            type="button"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 drop-shadow-md"
                        >
                            Save
                        </button>
                        <button onClick={() => onDeleteMachineClick()} type="button"
                            className="rounded-md bg-red-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 drop-shadow-md">
                            Delete
                        </button>
                    </div>
                </fieldset>
            </form> : <></>}
    </Main>
    );
}