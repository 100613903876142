import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import axios from "axios";
import Main from './Main';

export default function AddMachine() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [status, setStatus] = useState("");
    const [sensor, setSensor] = useState([]);
    const [attachSensor, setAttachSensor] = useState(new Set());

    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = sessionStorage.getItem("token");

    const machine_name_ref = useRef(null);
    const machine_type_ref = useRef(null);
    const process_name_ref = useRef(null);
    const site_name_ref = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        axios.get(baseURL + "/sensors/unattached", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).
            then((response) => {
                //console.log(response.data)
                setSensor(response.data.results);
            })

    }, []);
    const handleSubmitForm = async () => {
        const new_machine = {
            machine_name: machine_name_ref.current.value ? machine_name_ref.current.value : null,
            machine_type: machine_type_ref.current.value ? machine_type_ref.current.value : null,
            site_name: site_name_ref.current.value ? site_name_ref.current.value : null,
            process_name: process_name_ref.current.value ? process_name_ref.current.value : null,
            attached_sensor: Array.from(attachSensor),
        }
        const formData = new FormData();
        formData.append('new_machine', JSON.stringify(new_machine));
        formData.append('file', selectedFile);

        //console.log(formData);
        if (new_machine.machine_name === null)
            alert("Please fill in machine name!")
        else {
            await axios.post(baseURL + "/add_machine", formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => { //console.log(response.data)
                    if (response.data[0]["status"] == "success")
                        setStatus("success")
                    if (response.data[0]["status"] == "error")
                        setStatus("error")
                })
                .catch(error => {
                    console.log('Cannot add new machine', error);
                    setStatus("error")
                })
        }
    }
    let status_detail
    if (status == "success")
        status_detail =
            <div className="ml-5 border border-green-400 bg-green-50 rounded-md drop-shadow-md">
                <p className="p-6 text-lg font-medium text-sky-800 text-center">
                    <ion-icon name="checkmark-circle-outline" style={{ 'color': '#8de02c', 'font-size': '50px' }} className="text-center"></ion-icon><br />
                    Add machine successfully!<br />
                    <button type="submit" onClick={() => { setStatus(""); navigate(`/machines`) }}
                        className="mt-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Go back
                    </button>
                </p>

            </div>
    else if (status == "error")
        status_detail =
            <div className="ml-5 border border-red-400 bg-red-50 rounded-md drop-shadow-md">
                <p className="p-6 text-lg font-medium text-red-800 text-center">
                    <ion-icon name="alert-circle-outline" style={{ 'color': '#f00', 'font-size': '50px' }} className="text-center"></ion-icon><br />
                    Error, cannot add machine!<br />
                    <button type="submit" onClick={() => setStatus("")}
                        className="mt-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Retry again?
                    </button>
                </p>
            </div>
    const sensorList = sensor.map(s => <option value={s.sensor_id} key={s.sensor_id} />);
    const attachSensorList = Array.from(attachSensor).map(s => <span id='attachsensor_element'>{s} <ion-icon onClick={() => {
        setAttachSensor(new Set(Array.from(attachSensor).filter(_s => _s !== s)));
        setSensor([...sensor, { sensor_id: s }]);
    }} style={{ marginLeft: '5px', verticalAlign: 'sub', fontSize: '20px', color: 'red' }} name="close-circle">x</ion-icon></span>);
    return (<>
        <Main menuText={"Machines"}>
            {status_detail}
            {status == "" ?
                <form className="ml-5">
                    <center className="text-2xl font-bold">Add new machine</center>
                    <fieldset className="rounded-md border border-gray-300 drop-shadow-md">
                        <legend className="ml-4 text-xl font-semibold leading-7 text-gray-900">Machine Information</legend>
                        <div className="mt-4 ml-4">
                            <label htmlFor="machine_name">Machine name :</label>
                            <input type="text" id="machine_name" name="machine_name" ref={machine_name_ref} placeholder="Type machine name..." className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" />
                        </div>
                        <div className="mt-4 ml-4">
                            <label htmlFor="machine_type">Machine type :</label>
                            <input type="text" id="machine_type" name="machine_type" ref={machine_type_ref} placeholder="Type machine type..." className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" />
                        </div>
                        <div className="mt-4 ml-4">
                            <label htmlFor="process_name">Process name :</label>
                            <input type="text" id="process_name" name="process_name" ref={process_name_ref} placeholder="Type process name..." className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" />
                        </div>
                        <div className="mt-4 ml-4">
                            <label htmlFor="site_name">Site name :</label>
                            <input type="text" id="site_name" name="site_name" ref={site_name_ref} placeholder="Type site name..." className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" />
                        </div>
                        <div className="mt-4 ml-4 mb-4" id="Dropzone">


                            <Dropzone onDrop={acceptedFiles => {
                                setSelectedFile(acceptedFiles[0]);
                            }}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <center><b>Drag & Drop a machine image here, or click to select an image file</b></center>
                                        Machine Image:
                                        {selectedFile ?
                                            <>
                                                <img
                                                    alt="Dropped image"
                                                    className="h-64"
                                                    src={URL.createObjectURL(selectedFile)}
                                                />
                                                {selectedFile.name}<ion-icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); setSelectedFile(null); }} style={{ marginLeft: '5px', verticalAlign: 'sub', fontSize: '20px', color: 'red' }} name="close-circle"></ion-icon>
                                            </> : <></>
                                        }
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                        <label htmlFor="sensorlists" className="ml-4 mt-4">Choose sensors to attach from the list: </label>
                        <input list="unattached_sensors" name="sensorlists" id="sensorlists" className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" />
                        <datalist id="unattached_sensors">
                            {sensorList}
                        </datalist>
                        <button onClick={(e) => {

                            let _attachSensor = document.getElementById("sensorlists").value;

                            if (_attachSensor != "" && sensor.filter(s => s.sensor_id == _attachSensor).length > 0) {

                                setAttachSensor(new Set([...attachSensor, _attachSensor]));
                                setSensor(sensor.filter(_s => _s.sensor_id !== _attachSensor));
                                document.getElementById("sensorlists").value = "";
                                //console.log(attachSensor);
                            }
                            if (_attachSensor == "") alert("Please fill sensor id!");
                            else if (sensor.filter(s => s.sensor_id == _attachSensor).length == 0) alert("No this unattached sensor id in the system!");
                            e.preventDefault();

                        }} className="ml-4 rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 drop-shadow-md">attach sensor</button>
                        <br /><div className="mt-4 ml-4">Attached Sensors: {attachSensorList}</div>
                        <div className="ml-4 mb-6 mt-6 flex items-center justify-left gap-x-6">
                            <button type="reset" className="rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 drop-shadow-md">
                                Reset
                            </button>
                            <button onClick={() => navigate("/machines")}
                                type="button"
                                className="rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 drop-shadow-md"
                            >
                                Cancel
                            </button>
                            <button onClick={() => { handleSubmitForm() }}
                                type="button"
                                className="rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 drop-shadow-md"
                            >
                                Add machine
                            </button>
                        </div>
                    </fieldset>
                </form> : <></>}
        </Main>
    </>);
}