
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from './assets/imgs/techproplus.jpg'

export default function Navigation() {

    const [datetime, setDateTime] = useState(new Date())
    useEffect(() => {
        const intervalID_Date =setInterval(() => { setDateTime(new Date()) }, 1000)
        return () => {
            clearInterval(intervalID_Date);
        }
    }, []);

    return (
        <div className="navigation">
            <ul>
                <li>
                    <a href="#">
                        <span className="icon" id="brand">
                            {/*<ion-icon name="logo-react"></ion-icon>*/}
                            <div className="bg-white mt-2 rounded-lg drop-shadow-md"><img className="p-1" src={logo} /></div>
                        </span>
                        <span className="title">Techproplus</span>
                    </a>
                    <center className="text-white text-sm">{datetime.toString().substring(0, 24)}</center>
                </li>
                <li>
                    <Link to="/">
                        <span className="icon">
                            <ion-icon name="speedometer-outline"></ion-icon>
                        </span>
                        <span className="title">Dashboard</span>
                    </Link>
                </li>
                <li>
                    <Link to="/machines">
                        <span className="icon">
                            <ion-icon name="cog-outline"></ion-icon>
                        </span>
                        <span className="title">Machines</span>
                    </Link>
                </li>
                <li>
                    <Link to="/sensors">
                        <span className="icon">
                            <ion-icon name="pulse-outline"></ion-icon>
                        </span>
                        <span className="title">Sensors</span>
                    </Link>
                </li>
                <li>
                    <Link to="/alarms">
                        <span className="icon">
                            <ion-icon name="warning-outline"></ion-icon>
                        </span>
                        <span className="title">Alarms</span>
                    </Link>
                </li>
                <li>
                    <Link to="/collectors">
                        <span className="icon">
                            <ion-icon name="cloud-download-outline"></ion-icon>
                        </span>
                        <span className="title">Collectors</span>
                    </Link>
                </li>
                <li>
                    <Link to="/export">
                        <span className="icon">
                            <ion-icon name="download-outline"></ion-icon>
                        </span>
                        <span className="title">Export</span>
                    </Link>
                </li>
                <li>
                    <Link to="/users">
                        <span className="icon">
                            <ion-icon name="person-circle-outline"></ion-icon>
                        </span>
                        <span className="title">Users</span>
                    </Link>
                </li>
                <li>
                    <Link to="/logout">
                        <span className="icon">
                            <ion-icon name="log-out-outline"></ion-icon>
                        </span>
                        <span className="title">Logout</span>
                    </Link>
                </li>
            </ul>
        </div>
    );
}