import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
//Grid.js
import { Grid, html } from "gridjs";
import "gridjs/dist/theme/mermaid.css";


export default function SensorTable() {
  const navigate = useNavigate();
  const wrapperRef = useRef(null);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("token");

  const grid = new Grid({
    resizable: true,
    sort: true,
    search: true,
    pagination: {
      limit: 25,
    },
    autoWidth: true,
    columns: [{
      name: 'Sensor id',
      formatter: (cell) => 1 ? html(`<b>${cell}</b>`) : html(`<i>${cell}</i>`)
    },
      'Name', 'Machine name', 'Machine type', 'Site name', 'Process name', 'Date added', 'Date modified'],
    style: {
      table: {
        'white-space': 'nowrap',
      },
      td: {
        'background-color': 'transparent'
      },
    },
    server: {
      url: `${baseURL}/sensors`,
      data: (opts) => {
        return new Promise((resolve, reject) => {
          axios.get(opts.url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then(response => {
            //console.log(response);
            resolve({
              data: response.data.results.map(sensor => [sensor.sensor_id,
              sensor.name,
              sensor.machine_name,
              sensor.machine_type,
              sensor.site_name,
              sensor.process_name,
              sensor.date_added,
              sensor.date_modified]),
              total: response.data.count,
            });
          }).catch(error => {
            reject(error);
          });
        });
      },
    },
    className: {
      tr: 'gridjs-tr-class',
    }
  });
  grid.on('rowClick', (...args) => { onSensorRowClick(args[1]._cells[0].data) });
  const onSensorRowClick = (sensor_id) => {
    navigate('/sensors/' + sensor_id);
  };
  useEffect(() => {
    grid.render(wrapperRef.current);
  }, [])
  return (<div className="ml-5" ref={wrapperRef} />)
}