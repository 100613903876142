import { useParams, useNavigate } from "react-router-dom";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import Main from './Main'
//Grid.js
import { Grid, html } from "gridjs";
import "gridjs/dist/theme/mermaid.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


function AlarmTableById({ sensor_id }) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = sessionStorage.getItem("token");

    const wrapperRef = useRef(null);
    const navigate = useNavigate();
    const grid = new Grid({
        resizable: true,
        sort: true,
        search: false,

        pagination: {
            limit: 10,
            server: {
                url: (prev, page, limit) => {
                    //console.log("Fetching data from APIs")
                    if (prev.includes("?search=")) {
                        return `${prev}&limit=${limit}&offset=${page * limit}`
                    }
                    else
                        return `${prev}?limit=${limit}&offset=${page * limit}`
                }
            }
        },
        autoWidth: true,
        columns: [{
            name: 'Sensor time',
            formatter: (cell) => 1 ? html(`<b>${cell.slice(0, -3)}</b>`) : html(`<i>${cell}</i>`)
        },
        {
            name: 'Type',
            formatter: (_, row) => {
                let x = row.cells[4].data != "n/a" ? parseFloat(row.cells[4].data) : undefined;
                let y = row.cells[5].data != "n/a" ? parseFloat(row.cells[5].data) : undefined;
                let z = row.cells[6].data != "n/a" ? parseFloat(row.cells[6].data) : undefined;
                let temp = row.cells[7].data != "n/a" ? parseFloat(row.cells[7].data) : undefined;
                let warning_x = row.cells[8].data != "n/a" ? parseFloat(row.cells[8].data) : undefined;;
                let warning_y = row.cells[9].data != "n/a" ? parseFloat(row.cells[9].data) : undefined;;
                let warning_z = row.cells[10].data != "n/a" ? parseFloat(row.cells[10].data) : undefined;;
                let warning_temp = row.cells[11].data != "n/a" ? parseFloat(row.cells[11].data) : undefined;;
                let critical_x = row.cells[12].data != "n/a" ? parseFloat(row.cells[12].data) : undefined;;
                let critical_y = row.cells[13].data != "n/a" ? parseFloat(row.cells[13].data) : undefined;;
                let critical_z = row.cells[14].data != "n/a" ? parseFloat(row.cells[14].data) : undefined;;
                let critical_temp = row.cells[15].data != "n/a" ? parseFloat(row.cells[15].data) : undefined;;
                let label = ""
                if (x >= critical_x || y >= critical_y || z >= critical_z || temp >= critical_temp)
                    return html(`<ion-icon name="alert-circle" style='color:#f00;font-size:25px;text-align:center'></ion-icon>`)
                else if (x >= warning_x || y >= warning_y || z >= warning_z || temp >= warning_temp)
                    return html(`<ion-icon name="alert-circle" style='color:#e9b10a;font-size:25px;text-align:center'></ion-icon>`)

            }
        }, 'Sensor id', 'Name', 'x', 'y', 'z', 'temp', 'warning x', 'warning y', ' warning z', 'warning temp', 'critical x', 'critical y', ' critical z', 'critical temp',
        {
            name: 'description',
            formatter: (_, row) => {
                let x = row.cells[4].data != "n/a" ? parseFloat(row.cells[4].data) : undefined;
                let y = row.cells[5].data != "n/a" ? parseFloat(row.cells[5].data) : undefined;
                let z = row.cells[6].data != "n/a" ? parseFloat(row.cells[6].data) : undefined;
                let temp = row.cells[7].data != "n/a" ? parseFloat(row.cells[7].data) : undefined;
                let warning_x = row.cells[8].data != "n/a" ? parseFloat(row.cells[8].data) : undefined;;
                let warning_y = row.cells[9].data != "n/a" ? parseFloat(row.cells[9].data) : undefined;;
                let warning_z = row.cells[10].data != "n/a" ? parseFloat(row.cells[10].data) : undefined;;
                let warning_temp = row.cells[11].data != "n/a" ? parseFloat(row.cells[11].data) : undefined;;
                let critical_x = row.cells[12].data != "n/a" ? parseFloat(row.cells[12].data) : undefined;;
                let critical_y = row.cells[13].data != "n/a" ? parseFloat(row.cells[13].data) : undefined;;
                let critical_z = row.cells[14].data != "n/a" ? parseFloat(row.cells[14].data) : undefined;;
                let critical_temp = row.cells[15].data != "n/a" ? parseFloat(row.cells[15].data) : undefined;;
                let des = ""
                if (x >= critical_x) des += "critical_x,"
                if (y >= critical_y) des += "critical_y,"
                if (z >= critical_z) des += "critical_z,"
                if (temp >= critical_temp) des += "critical_temp,"
                if (x >= warning_x) des += "warning_x,"
                if (y >= warning_y) des += "warning_y,"
                if (z >= warning_z) des += "warning_z,"
                if (temp >= warning_temp) des += "warning_temp,"

                des = des.slice(0, -1)
                return html(`${des}`)
            }
        }],
        style: {
            table: {
                'white-space': 'nowrap',
            },
            td: {
                'background-color': 'transparent'
            },
        },
        server: {
            url: `${baseURL}/alarms/${sensor_id}`,
            data: (opts) => {
                return new Promise((resolve, reject) => {
                    axios.get(opts.url, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }).then(response => {
                        console.log(response);
                        resolve({
                            data: response.data.results.map(sensor => [sensor.sensor_time,
                                "type",
                            sensor.sensor_id,
                            sensor.name,
                            sensor.x ? sensor.x.toFixed(2) : "n/a",
                            sensor.y ? sensor.y.toFixed(2) : "n/a",
                            sensor.z ? sensor.z.toFixed(2) : "n/a",
                            sensor.temp ? sensor.temp.toFixed(2) : "n/a",
                            sensor.warning_x ? sensor.warning_x.toFixed(2) : "n/a",
                            sensor.warning_y ? sensor.warning_y.toFixed(2) : "n/a",
                            sensor.warning_z ? sensor.warning_z.toFixed(2) : "n/a",
                            sensor.warning_temp ? sensor.warning_temp.toFixed(2) : "n/a",
                            sensor.critical_x ? sensor.critical_x.toFixed(2) : "n/a",
                            sensor.critical_y ? sensor.critical_y.toFixed(2) : "n/a",
                            sensor.critical_z ? sensor.critical_z.toFixed(2) : "n/a",
                            sensor.critical_temp ? sensor.critical_temp.toFixed(2) : "n/a",
                                "description"]),
                            total: response.data.count,
                        });
                    }).catch(error => {
                        reject(error);
                    });
                });
            },
        },
        className: {
            tr: 'gridjs-tr-class',
        }
    });
    //grid.on('rowClick', (...args) => { onSensorRowClick(args[1]._cells[2].data)});
    //const onSensorRowClick = (sensor_id) => {
    //navigate('/sensors/'+sensor_id);
    //};
    useEffect(() => {
        grid.render(wrapperRef.current)
    }, [sensor_id])
    return (<div className="ml-5" ref={wrapperRef} />)
}
export function SensorInformation({ sensor_id }) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const [sensorInfo, setSensorInfo] = useState({});
    const navigate = useNavigate();
    const [collectorlist, setCollectorList] = useState([])
    const token = sessionStorage.getItem("token");

    useEffect(() => {
        axios.get(`${baseURL}/collectors?search=&limit=100&offset=0`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                setCollectorList(response.data.results);
            });
        axios.get(`${baseURL}/sensors/${sensor_id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                //console.log(response.data)
                setSensorInfo(response.data);
            })
            .catch((error) => { console.log('Cannot load sensor information', error); })
            .finally(() => { });
    }, [sensor_id]);
    const clist = collectorlist.map(c => <option key={c.collector_id} value={c.collector_id}>{c.collector_name}</option>)
    return (
        <>
            <fieldset className="rounded-md border border-gray-300 drop-shadow-md">
                <legend className="ml-4 text-xl font-semibold leading-7 text-gray-900">Sensor Information</legend>
                <div className="mt-4 ml-4">
                    <label htmlFor="sensor_id">Sensor id :</label>
                    <input type="text" id="sensor_id" name="sensor_id" value={sensorInfo.sensor_id} disabled className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" />
                    <label htmlFor="name" className="ml-4">Sensor name :</label>
                    <input type="text" id="name" name="name" value={sensorInfo.name} disabled className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" />
                </div>
                <div className="mt-4 ml-4 mb-4">
                    <label htmlFor="point_id">Point id :</label>
                    <input type="text" id="point_id" name="point_id" value={sensorInfo.point_id} disabled className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" />
                    <label htmlFor="collector_id" className="ml-4">Collector :</label>
                    <select name="collector_id" id="collector_id" key={sensorInfo.sensors_collector_id} value={sensorInfo.sensors_collector_id ? sensorInfo.sensors_collector_id : -1} disabled className="ml-4 rounded-md border border-gray-300 w-80 h-10">
                        <option value="-1" disabled>Choose Collector name</option>
                        {clist}
                    </select>
                </div>
            </fieldset>
            <fieldset className="mt-4 rounded-md border border-gray-300 drop-shadow-md">
                <legend className="ml-4 text-xl font-semibold leading-7 text-gray-900">Machine Information</legend>
                <div className="mt-4 ml-4 mb-4">
                    <label htmlFor="machine_name">Machine name :</label>
                    <input type="text" id="machine_name" name="machine_name" value={sensorInfo.machine_name} disabled className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" />
                    <label htmlFor="machine_class" className="ml-4">Machine Type :</label>
                    <input type="text" id="machine_class" name="machine_class" value={sensorInfo.machine_type} disabled className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" /><br />
                </div>
                <div className="mt-4 ml-4 mb-4">
                    <label htmlFor="site_name">Site name :</label>
                    <input type="text" id="site_name" name="site_name" value={sensorInfo.site_name} disabled className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" />
                    <label htmlFor="process_name" className="ml-4">Process name :</label>
                    <input type="text" id="machine_class" name="machine_class" value={sensorInfo.process_name} disabled className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" /><br />
                </div>
                <div className="mt-4 ml-4 mb-4">
                    {sensorInfo.image_name ? <>
                        <img
                            alt="Dropped image"
                            className="h-64"
                            src={baseURL + '/images/' + sensorInfo.sensors_machine_id + '_' + sensorInfo.image_name + '.jpg'}
                        />
                        {sensorInfo.image_name}</> : <></>
                    }
                </div>
            </fieldset>
            <fieldset className="mt-4 rounded-md border border-gray-300 drop-shadow-md">
                <legend className="ml-4 text-xl font-semibold leading-7 text-gray-900">Warning Alarm</legend>
                <div className="mt-4 ml-4">
                    <label htmlFor="warning_x">Warning x :</label>
                    <input type="text" id="warning_x" name="warning_x" value={sensorInfo.warning_x} disabled className="text-center ml-4 rounded-md border border-gray-300 w-20 h-10" /> mm/s
                    <label htmlFor="warning_y" className="ml-4">Warning y :</label>
                    <input type="text" id="warning_y" name="warning_y" value={sensorInfo.warning_y} disabled className="text-center ml-4 rounded-md border border-gray-300 w-20 h-10" /> mm/s
                    <label htmlFor="warning_z" className="ml-4">Warning z :</label>
                    <input type="text" id="warning_z" name="warning_z" value={sensorInfo.warning_z} disabled className="text-center ml-4 rounded-md border border-gray-300 w-20 h-10" /> mm/s
                </div>
                <div className="mt-4 mb-4">
                    <label htmlFor="warning_temp" className="ml-4">Warning temperature :</label>
                    <input type="text" id="warning_temp" name="warning_temp" value={sensorInfo.warning_temp} disabled className="text-center ml-4 rounded-md border border-gray-300 w-20 h-10" /> &#8451;
                </div>
            </fieldset>
            <fieldset className="mt-4 rounded-md border border-gray-300 drop-shadow-md">
                <legend className="ml-4 text-xl font-semibold leading-7 text-gray-900">Critical Alarm</legend>
                <div className="mt-4 ml-4">
                    <label htmlFor="critical_x">Critical x :</label>
                    <input type="text" id="critical_x" name="critical_x" value={sensorInfo.critical_x} disabled className="text-center ml-4 rounded-md border border-gray-300 w-20 h-10" /> mm/s
                    <label htmlFor="critical_y" className="ml-4">Critical y :</label>
                    <input type="text" id="critical_y" name="critical_y" value={sensorInfo.critical_y} disabled className="text-center ml-4 rounded-md border border-gray-300 w-20 h-10" /> mm/s
                    <label htmlFor="critical_z" className="ml-4">Critical z :</label>
                    <input type="text" id="critical_z" name="critical_y" value={sensorInfo.critical_z} disabled className="text-center ml-4 rounded-md border border-gray-300 w-20 h-10" /> mm/s
                </div>
                <div className="mt-4 mb-4">
                    <label htmlFor="critical_temp" className="ml-4">Critical temperature :</label>
                    <input type="text" id="critical_temp" name="critical_temp" value={sensorInfo.critical_temp} disabled className="text-center ml-4 rounded-md border border-gray-300 w-20 h-10" /> &#8451;
                </div>
            </fieldset>
            <div className="mb-6 mt-6 flex items-center justify-left gap-x-6">
                <button onClick={() => navigate(`/sensors/edit/${sensor_id}`)} type="button"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 drop-shadow-md">
                    Edit Sensor
                </button>
            </div>
        </>);
}

export default function SensorDetails() {
    const { showAlarm } = useParams();
    const { sensor_id } = useParams();
    const [sensorinfo, setSensorInfo] = useState({});
    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = sessionStorage.getItem("token");
    useEffect(() => {
        axios.get(`${baseURL}/sensors/${sensor_id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                setSensorInfo(response.data);
            });
    }, [sensor_id]);
    return (
        <Main menuText={"Sensors"}>
            <SensorChart sensor_id={sensor_id} name={sensorinfo.name} />
            {showAlarm == "showAlarm" ?
                <div className="mt-5">
                    <AlarmTableById sensor_id={sensor_id} />
                </div> : <></>
            }
            {showAlarm == "showAlarm" ?
                <center className="mt-5">
                    <button onClick={() => navigate(`/sensors/${sensor_id}`)} type="button"
                        className="rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 drop-shadow-md">
                        Hide Alarms
                    </button>
                </center> :
                <center className="mt-5">
                    <button onClick={() => navigate(`/sensors/${sensor_id}/showAlarm`)} type="button"
                        className="rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 drop-shadow-md">
                        Show Alarms
                    </button></center>
            }
            <div className="ml-5 mt-5">
                <SensorInformation sensor_id={sensor_id} />
            </div>
        </Main>);
}

export function SensorChart({ sensor_id, name }) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = sessionStorage.getItem("token");
    const [sensorData, setSensorData] = useState([]);
    const [showX, setShowX] = useState(true);
    const [showY, setShowY] = useState(true);
    const [showZ, setShowZ] = useState(true);
    const [showTemp, setShowTemp] = useState(true);
    const [loading, setLoading] = useState(true);


    const today = new Date(); // Create a Date object for today
    const yesterday = new Date(today); // Clone the Date object
    yesterday.setDate(today.getDate() - 1); // Subtract one day
    yesterday.setHours(0, 0, 0, 0);
    const [startDate, setStartDate] = useState(yesterday);
    today.setHours(23, 59, 59, 999);
    const [endDate, setEndDate] = useState(today);


    useEffect(() => {
        setLoading(true);
        if (startDate > endDate) {
            alert("Start date should before End date!")
            setSensorData([]);
            setLoading(false);
        }
        else if (endDate < startDate) {
            alert("End date should after Start date!")
            setSensorData([]);
            setLoading(false);
        }
        else {
            //console.log(`${baseURL}/sensor_data/${sensor_id}?start=${startDate.toISOString()}&end=${endDate.toISOString()}`)
            axios.get(`${baseURL}/sensor_data/${sensor_id}?start=${startDate.toISOString()}&end=${endDate.toISOString()}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    for (let i = 0; i < response.data.length; i++)
                        response.data[i].sensor_time = response.data[i].sensor_time.slice(0, -3);
                    //console.log(response.data);
                    setSensorData(response.data);
                })

                .catch(error => { console.error('Error fetching data:', error); setSensorData([]); setLoading(false); })
                .finally(() => { setLoading(false); });
        }
    }, [startDate, endDate, sensor_id]);
    //const sensorData = generateRandomData()
    return (<>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="mb-5 text-2xl font-semibold text-indigo-900">
                {name}({sensor_id}):{sensorData.length} points
            </div>
        </div>
        <div className="mb-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <span className="mr-3 font-medium">Start date:</span><DatePicker className="rounded-md border border-gray-300 w-25 h-10" selected={startDate} onChange={(date) => { setStartDate(date) }} /><br />
            <span className="ml-3 mr-3 font-medium">End date:</span><DatePicker className="rounded-md border border-gray-300 w-25 h-10" selected={endDate} onChange={(date) => setEndDate(date)} /><br />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {loading ?
                <div style={{ marginTop: '50px' }}>
                    <BeatLoader
                        color={"#2a2185"}
                        loading={loading}
                        size={10}
                        aria-label="Loading Spinner"
                        data-testid="loader" />
                </div> :
                <div>
                    <LineChart width={1000} height={300} data={sensorData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="sensor_time" />
                        <YAxis yAxisId="left">
                            <Label value="mm/s" position="insideLeft" angle={-90} />
                        </YAxis>
                        <YAxis yAxisId="right" orientation="right">
                            <Label value="Temp(c)" position="insideRight" angle={-90} />
                        </YAxis>
                        <Tooltip />
                        <Legend />
                        {showX && !showY && !showZ && !showTemp ?
                            <>
                                <Line type="monotone" dataKey="critical_x" stroke="#D20103" strokeDasharray="3 3" strokeWidth={2} yAxisId="left" dot={false} />
                                <Line type="monotone" dataKey="warning_x" stroke="#FE9900" strokeDasharray="3 3" strokeWidth={2} yAxisId="left" dot={false} />
                            </> : <></>}
                        {!showX && showY && !showZ && !showTemp ?
                            <>
                                <Line type="monotone" dataKey="critical_y" stroke="#D20103" strokeDasharray="3 3" strokeWidth={2} yAxisId="left" dot={false} />
                                <Line type="monotone" dataKey="warning_y" stroke="#FE9900" strokeDasharray="3 3" strokeWidth={2} yAxisId="left" dot={false} />
                            </> : <></>}
                        {!showX && !showY && showZ && !showTemp ?
                            <>
                                <Line type="monotone" dataKey="critical_z" stroke="#D20103" strokeDasharray="3 3" strokeWidth={2} yAxisId="left" dot={false} />
                                <Line type="monotone" dataKey="warning_z" stroke="#FE9900" strokeDasharray="3 3" strokeWidth={2} yAxisId="left" dot={false} />
                            </> : <></>}
                        {!showX && !showY && !showZ && showTemp ?
                            <>
                                <Line type="monotone" dataKey="critical_temp" stroke="#D20103" strokeDasharray="3 3" yAxisId="right" strokeWidth={2} dot={false} />
                                <Line type="monotone" dataKey="warning_temp" stroke="#FE9900" strokeDasharray="3 3" yAxisId="right" strokeWidth={2} dot={false} />
                            </> : <></>}
                        {showX ? <Line type="monotone" dataKey="x" stroke="#8884d8" yAxisId="left" dot={false} /> : <></>}
                        {showY ? <Line type="monotone" dataKey="y" stroke="#82ca9d" yAxisId="left" dot={false} /> : <></>}
                        {showZ ? <Line type="monotone" dataKey="z" stroke="#ffc658" yAxisId="left" dot={false} /> : <></>}
                        {showTemp ? <Line type="monotone" dataKey="temp" stroke="#ff7300" yAxisId="right" dot={false} /> : <></>}
                    </LineChart>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <input type="checkbox" id="checkboxX" checked={showX} onChange={() => setShowX(!showX)} />
                        <label htmlFor="checkboxX" style={{ marginLeft: '5px' }}>X axis</label>
                        <input type="checkbox" id="checkboxY" checked={showY} onChange={() => setShowY(!showY)} style={{ marginLeft: '10px' }} />
                        <label htmlFor="checkboxY" style={{ marginLeft: '5px' }}>Y axis</label>
                        <input type="checkbox" id="checkboxZ" checked={showZ} onChange={() => setShowZ(!showZ)} style={{ marginLeft: '10px' }} />
                        <label htmlFor="checkboxZ" style={{ marginLeft: '5px' }}>Z axis</label>
                        <input type="checkbox" id="checkboxTemp" checked={showTemp} onChange={() => setShowTemp(!showTemp)} style={{ marginLeft: '10px' }} />
                        <label htmlFor="checkboxTemp" style={{ marginLeft: '5px' }}>Temperature</label>
                    </div>
                </div>
            }
        </div>
    </>
    );
}