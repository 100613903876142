import React, { useState } from 'react';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const baseURL = process.env.REACT_APP_BASE_URL;
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${baseURL}/login`, {
                username,
                password
            });

            const token = response.data.access_token;
            sessionStorage.setItem('token', token);

            // Decode token to get user information (optional)
            const decodedToken = jwtDecode(token);
            console.log(decodedToken['sub']);

            // Redirect to protected page or home page
            window.location.href = '/';
        } catch (error) {
            setError('Invalid Username or Password!');
        }
    };

    return (
        <center><div className="login-container">
            <h1>Machine Monitoring Dashboard</h1>
            <form onSubmit={handleSubmit}>
                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" autoComplete="username" required/>
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" autoComplete="current-password" required/>
                <button type="submit">Login</button>
            </form>
            {error && <p>{error}</p>}
        </div></center>
    );
}

export default Login;