import Modal from 'react-modal';
import { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
//Grid.js
import { Grid, html } from "gridjs";
import "gridjs/dist/theme/mermaid.css";

export default function ModalSensorCard({ p, myRef = { current: {} } }) {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("token");

  const wrapperRef = useRef(null);
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [endpoint, setEndPoint] = useState("");


  const grid = new Grid({
    resizable: true,
    sort: true,
    search: true,
    search: {
      server: {
        url: (prev, keyword) => `${prev}?search=${keyword}`
      }
    },
    pagination: {
      limit: 25,
      server: {
        url: (prev, page, limit) => {
          //console.log("Fetching data from APIs")
          if (prev.includes("?search=")) {
            if (p == undefined)
              return `${prev}&limit=${limit}&offset=${page * limit}`
            else
              return `${prev}&limit=${limit}&offset=${page * limit}&process=${p}`
          }
          else {
            if (p == undefined)
              return `${prev}?limit=${limit}&offset=${page * limit}`
            else
              return `${prev}?limit=${limit}&offset=${page * limit}&process=${p}`

          }

        }
      }
    },
    autoWidth: true,
    columns: ['Sensor id', 'Name', 'Machine name', 'Machine Type', 'Site name', 'Process name'],
    style: {
      table: {
        'white-space': 'nowrap',
      },
      td: {
        'background-color': 'transparent'
      },
    },
    server: {
      url: `${baseURL}/${endpoint}`,
      data: (opts) => {
        return new Promise((resolve, reject) => {
          axios.get(opts.url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then(response => {
            //console.log(response);
            resolve({
              data: response.data.results.map(sensor => [sensor.sensor_id,
              sensor.name,
              sensor.machine_name ? sensor.machine_name : "n/a",
              sensor.machine_type ? sensor.machine_type : "n/a",
              sensor.site_name ? sensor.site_name : "n/a",
              sensor.process_name ? sensor.process_name : "n/a",
              ]),
              total: response.data.count,
            });
          }).catch(error => {
            reject(error);
          });
        });
      },
    },
    className: {
      tr: 'gridjs-tr-class',
    }
  });
  grid.on('rowClick', (...args) => { onSensorRowClick(args[1]._cells[0].data) });
  const onSensorRowClick = (sensor_id) => {
    setIsOpen(false);
    navigate('/sensors/' + sensor_id);
  };

  const customStyles = {
    content: {
      width: '70%',
      height: '80%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  function openModal(ep) {
    setEndPoint(ep);
    setIsOpen(true);
  }

  function afterOpenModal() {
    grid.render(wrapperRef.current);
  }

  function closeModal() {
    setIsOpen(false);
  }

  myRef.current.openModal = openModal;
  myRef.current.afterOpenModal = afterOpenModal;
  myRef.current.closeModal = closeModal;

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="card status">
      <div className="flex justify-end">
        <button className="rounded-md bg-red-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 drop-shadow-md" onClick={closeModal}>close</button>
      </div>
      {endpoint == "countnormalsensors" ? <span style={{ color: "#2a2185" }} className="ml-5 mb-2 text-lg">Online sensors that have transmitted data within 24 hours:</span> : <></>}
      {endpoint == "countabnormalsensors" ? <span style={{ color: "#2a2185" }} className="ml-5 mb-2 text-lg">Unreachable sensors that haven't transmitted data within 24 hours:</span> : <></>}
      {endpoint == "countnotworksensors" ? <span style={{ color: "#2a2185" }} className="ml-5 mb-2 text-lg">Not working sensors that never transmitted data:</span> : <></>}
      {endpoint == "countsensors" ? <span style={{ color: "#2a2185" }} className="ml-5 mb-2 text-lg">Total sensors installed:</span> : <></>}
      <div className="ml-5" ref={wrapperRef} />
    </Modal>)
}

