
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Main from "./Main";

export default function EditSensor() {
    const { sensor_id } = useParams();

    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = sessionStorage.getItem("token");
    const [sensorInfo, setSensorInfo] = useState({});
    const navigate = useNavigate();

    const sensor_id_ref = useRef(null);
    const name_ref = useRef(null);
    const point_id_ref = useRef(null);
    const collector_id_ref = useRef(null);
    const warning_x_ref = useRef(null);
    const warning_y_ref = useRef(null);
    const warning_z_ref = useRef(null);
    const warning_temp_ref = useRef(null);
    const critical_x_ref = useRef(null);
    const critical_y_ref = useRef(null);
    const critical_z_ref = useRef(null);
    const critical_temp_ref = useRef(null);

    const [status, setStatus] = useState("");
    const [newsensorid, setNewSensorId] = useState(sensor_id);
    const [collectorlist, setCollectorList] = useState([])

    const onDeleteSensorClick = async () => {

        if (window.confirm(`Are you sure to delete sensor id ${sensor_id}?`)) {
            await axios.delete(`${baseURL}/delete_sensor/${sensor_id}`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => { //console.log(response.data)
                    if (response.data[0]["status"] == "success")
                        setStatus("success2")
                    if (response.data[0]["status"] == "error")
                        setStatus("error2")

                })
                .catch(error => {
                    console.log('Cannot update sensor', error);
                    setStatus("error2")
                });
        }

    }
    const onUpdateSensorClick = async () => {
        const update_sensor = {
            sensor_id: sensor_id_ref.current.value ? sensor_id_ref.current.value : null,
            name: name_ref.current.value ? name_ref.current.value : null,
            point_id: point_id_ref.current.value ? point_id_ref.current.value : null,
            collector_id: collector_id_ref.current.value != "-1" ? collector_id_ref.current.value : null,
            warning_x: parseFloat(warning_x_ref.current.value),
            warning_y: parseFloat(warning_y_ref.current.value),
            warning_z: parseFloat(warning_z_ref.current.value),
            warning_temp: parseFloat(warning_temp_ref.current.value),
            critical_x: parseFloat(critical_x_ref.current.value),
            critical_y: parseFloat(critical_y_ref.current.value),
            critical_z: parseFloat(critical_z_ref.current.value),
            critical_temp: parseFloat(critical_temp_ref.current.value)
        }
        if (update_sensor.sensor_id === "" || update_sensor.name === "")
            alert("Please fill in sensor id and name!")
        else {
            //alert(JSON.stringify(new_sensor))
            await axios.put(`${baseURL}/update_sensor/${newsensorid}`, update_sensor,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => { //console.log(response.data)
                    if (response.data[0]["status"] == "success")
                        setStatus("success")
                    setNewSensorId(update_sensor.sensor_id)
                    if (response.data[0]["status"] == "error")
                        setStatus("error")

                })
                .catch(error => {
                    console.log('Cannot update sensor', error);
                    setStatus("error")
                });
        }
    }
    let status_detail
    if (status == "success")
        status_detail =
            <div className="ml-5 border border-green-400 bg-green-50 rounded-md drop-shadow-md">
                <p className="p-6 text-lg font-medium text-sky-800 text-center">
                    <ion-icon name="checkmark-circle-outline" style={{ 'color': '#8de02c', 'fontSize': '50px' }} className="text-center"></ion-icon><br />
                    Update sensor successfully!<br />
                    <button type="submit" onClick={() => { setStatus(""); navigate(`/sensors/${newsensorid}`) }}
                        className="mt-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Go back
                    </button>
                </p>

            </div>
    else if (status == "error")
        status_detail =
            <div className="ml-5 border border-red-400 bg-red-50 rounded-md drop-shadow-md">
                <p className="p-6 text-lg font-medium text-red-800 text-center">
                    <ion-icon name="alert-circle-outline" style={{ 'color': '#f00', 'fontSize': '50px' }} className="text-center"></ion-icon><br />
                    Error, cannot update sensor!<br />
                    <button type="submit" onClick={() => setStatus("")}
                        className="mt-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Retry again?
                    </button>
                </p>
            </div>
    else if (status == "success2")
        status_detail =
            <div className="ml-5 border border-green-400 bg-green-50 rounded-md drop-shadow-md">
                <p className="p-6 text-lg font-medium text-sky-800 text-center">
                    <ion-icon name="checkmark-circle-outline" style={{ 'color': '#8de02c', 'font-size': '50px' }} className="text-center"></ion-icon><br />
                    Delete sensor successfully!<br />
                    <button type="submit" onClick={() => { setStatus(""); navigate(`/sensors/`) }}
                        className="mt-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Back to Sensor Page
                    </button>
                </p>

            </div>
    else if (status == "error2")
        status_detail =
            <div className="ml-5 border border-red-400 bg-red-50 rounded-md drop-shadow-md">
                <p className="p-6 text-lg font-medium text-red-800 text-center">
                    <ion-icon name="alert-circle-outline" style={{ 'color': '#f00', 'font-size': '50px' }} className="text-center"></ion-icon><br />
                    Error, cannot delete sensor!<br />
                    <button type="submit" onClick={() => { setStatus(""); navigate(`/edit_sensor/${newsensorid}`) }}
                        className="mt-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Go back
                    </button>
                </p>
            </div>
    else
        status_detail = <></>

    useEffect(() => {
        axios.get(`${baseURL}/collectors?search=&limit=100&offset=0`,{
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                setCollectorList(response.data.results);
            });
        axios.get(`${baseURL}/sensors/${newsensorid}`,{
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                //console.log(response.data)
                setSensorInfo(response.data);
                if (document.getElementById("sensor_id"))
                    document.getElementById("sensor_id").focus()
            });
    }, []);
    const clist = collectorlist.map(c => <option key={c.collector_id} value={c.collector_id}>{c.collector_name}</option>)
    return (<>
        <Main menuText={"Sensors"}>
            {status_detail}
            {status == "" ?
                <form className="ml-5">
                    <center className="text-2xl font-bold">Edit sensor</center>
                    <fieldset className="rounded-md border border-gray-300 drop-shadow-md">
                        <legend className="ml-4 text-xl font-semibold leading-7 text-gray-900">Sensor Information</legend>
                        <div className="mt-4 ml-4">
                            <label htmlFor="sensor_id">Sensor id :</label>
                            <input type="text" id="sensor_id" name="sensor_id" ref={sensor_id_ref} defaultValue={sensorInfo.sensor_id} className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10"
                            />
                            <label htmlFor="name" className="ml-4">Sensor name :</label>
                            <input type="text" id="name" name="name" ref={name_ref} defaultValue={sensorInfo.name} className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10"
                                placeholder="Type sensor name..." />
                        </div>
                        <div className="mt-4 ml-4 mb-4">
                            <label htmlFor="point_id">Point id :</label>
                            <input type="text" id="point_id" name="point_id" ref={point_id_ref} defaultValue={sensorInfo.point_id} className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10"
                                placeholder="For Analytix api only, type point id..." />
                            <label htmlFor="collector_id" className="ml-4">Collector :</label>
                            <select name="collector_id" id="collector_id" ref={collector_id_ref} key={sensorInfo.sensors_collector_id} value={sensorInfo.sensors_collector_id?sensorInfo.sensors_collector_id:-1} onChange={(e) => { setSensorInfo({ ...sensorInfo, sensors_collector_id: e.target.value }) }} className="ml-4 rounded-md border border-gray-300 w-80 h-10">
                                <option value="-1">Choose Collector name</option>
                                {clist}
                            </select>
                        </div>
                    </fieldset>
                    <fieldset className="mt-4 rounded-md border border-gray-300 drop-shadow-md">
                        <legend className="ml-4 text-xl font-semibold leading-7 text-gray-900">Machine Information</legend>
                        <div className="mt-4 ml-4 mb-4">
                            <label htmlFor="machine_name">Machine name :</label>
                            <input type="text" id="machine_name" name="machine_name" value={sensorInfo.machine_name} disabled className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10"
                                placeholder="Type machine name..." />
                            <label htmlFor="machine_type" className="ml-4">Machine type :</label>
                            <input type="text" id="machine_type" name="machine_type" value={sensorInfo.machine_type} disabled className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10"
                                placeholder="Type machine class..." /><br />
                        </div>
                        <div className="mt-4 ml-4 mb-4">
                            <label htmlFor="site_name">Site name :</label>
                            <input type="text" id="site_name" name="site_name" value={sensorInfo.site_name} disabled className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10"
                                placeholder="Type site name..." />
                            <label htmlFor="process_name" className="ml-4">Process name :</label>
                            <input type="text" id="machine_class" name="machine_class" value={sensorInfo.process_name} disabled className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10"
                                placeholder="Type machine class..." /><br />
                        </div>
                        <div className="mt-4 ml-4 mb-4">
                            {sensorInfo.image_name ? <>
                                <img
                                    alt="Dropped image"
                                    className="h-64"
                                    src={baseURL + '/images/' + sensorInfo.sensors_machine_id + '_' + sensorInfo.image_name + '.jpg'}
                                />
                                {sensorInfo.image_name}</> : <></>
                            }
                        </div>
                    </fieldset>
                    <fieldset className="mt-4 rounded-md border border-gray-300 drop-shadow-md">
                        <legend className="ml-4 text-xl font-semibold leading-7 text-gray-900">Warning Alarm</legend>
                        <div className="mt-4 ml-4">
                            <label htmlFor="warning_x">Warning x :</label>
                            <input type="text" id="warning_x" name="warning_x" ref={warning_x_ref} defaultValue={sensorInfo.warning_x} className="text-center ml-4 rounded-md border border-gray-300 w-20 h-10" /> mm/s
                            <label htmlFor="warning_y" className="ml-4">Warning y :</label>
                            <input type="text" id="warning_y" name="warning_y" ref={warning_y_ref} defaultValue={sensorInfo.warning_y} className="text-center ml-4 rounded-md border border-gray-300 w-20 h-10" /> mm/s
                            <label htmlFor="warning_z" className="ml-4">Warning z :</label>
                            <input type="text" id="warning_z" name="warning_z" ref={warning_z_ref} defaultValue={sensorInfo.warning_z} className="text-center ml-4 rounded-md border border-gray-300 w-20 h-10" /> mm/s
                        </div>
                        <div className="mt-4 mb-4">
                            <label htmlFor="warning_temp" className="ml-4">Warning temperature :</label>
                            <input type="text" id="warning_temp" name="warning_temp" ref={warning_temp_ref} defaultValue={sensorInfo.warning_temp} className="text-center ml-4 rounded-md border border-gray-300 w-20 h-10" /> &#8451;
                        </div>
                    </fieldset>
                    <fieldset className="mt-4 rounded-md border border-gray-300 drop-shadow-md">
                        <legend className="ml-4 text-xl font-semibold leading-7 text-gray-900">Critical Alarm</legend>
                        <div className="mt-4 ml-4">
                            <label htmlFor="critical_x">Critical x :</label>
                            <input type="text" id="critical_x" name="critical_x" ref={critical_x_ref} defaultValue={sensorInfo.critical_x} className="text-center ml-4 rounded-md border border-gray-300 w-20 h-10" /> mm/s
                            <label htmlFor="critical_y" className="ml-4">Critical y :</label>
                            <input type="text" id="critical_y" name="critical_y" ref={critical_y_ref} defaultValue={sensorInfo.critical_y} className="text-center ml-4 rounded-md border border-gray-300 w-20 h-10" /> mm/s
                            <label htmlFor="critical_z" className="ml-4">Critical z :</label>
                            <input type="text" id="critical_z" name="critical_y" ref={critical_z_ref} defaultValue={sensorInfo.critical_z} className="text-center ml-4 rounded-md border border-gray-300 w-20 h-10" /> mm/s
                        </div>
                        <div className="mt-4 mb-4">
                            <label htmlFor="critical_temp" className="ml-4">Critical temperature :</label>
                            <input type="text" id="critical_temp" name="critical_temp" ref={critical_temp_ref} defaultValue={sensorInfo.critical_temp} className="text-center ml-4 rounded-md border border-gray-300 w-20 h-10" /> &#8451;
                        </div>
                    </fieldset>
                    <div className="mb-6 mt-6 flex items-center justify-left gap-x-6">
                        <button type="reset" className="text-base font-semibold leading-6 text-gray-900 drop-shadow-md">
                            Reset
                        </button>
                        <button onClick={() => navigate(`/sensors/${newsensorid}`)} type="button"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 drop-shadow-md">
                            Back
                        </button>
                        <button onClick={() => onUpdateSensorClick()} type="button"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 drop-shadow-md">
                            Update Sensor
                        </button>
                        <button onClick={() => onDeleteSensorClick()} type="button"
                            className="rounded-md bg-red-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 drop-shadow-md">
                            Delete Sensor
                        </button>
                    </div>
                </form> : <></>}
        </Main>
    </>)
}