import { CSVLink, CSVDownload } from "react-csv";
import DatePicker from "react-datepicker";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import Main from './Main'
export const Checkbox = ({ isChecked, label, checkHandler, index }) => {
    return (
        <div>
            <input
                type="checkbox"
                id={`checkbox-${index}`}
                checked={isChecked}
                onChange={checkHandler}
            />
            <label htmlFor={`checkbox-${index}`}>{label}</label>
        </div>
    )
}
export default function ExportPage() {
    const today = new Date(); // Create a Date object for today
    const yesterday = new Date(today); // Clone the Date object
    yesterday.setDate(today.getDate() - 1); // Subtract one day
    yesterday.setHours(0, 0, 0, 0);
    const [startDate, setStartDate] = useState(yesterday);
    today.setHours(23, 59, 0, 0);
    const [endDate, setEndDate] = useState(today);
    const [pname, setPname] = useState([]);
    const pname_ref = useRef(null);
    const [selectPname, setSelectPname] = useState("");
    const [downloadlink, setDownloadLink] = useState(false);
    const [csvData, setCSVData] = useState({})
    const [sensors, setSensors] = useState([]);
    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = sessionStorage.getItem("token");

    useEffect(() => {
        axios.get(`${baseURL}/process`,{
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                setPname(response.data.processes);
            }).finally(() => { });

        axios.get(`${baseURL}/sensors?q=`,{
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                const checkboxSensors = response.data.results.map(s => ({ ...s, checked: false }))
                setSensors(checkboxSensors)
            })
    }, []);
    const onClickDownload = () => {
        return <CSVDownload data={csvData} target="_blank" />
    }
    const updateCheckStatus = id => {
        setSensors(
            sensors.map((s) =>
                s.sensor_id === id
                    ? { ...s, checked: !s.checked }
                    : s
            )
        )
    }

    //console.log(sensors)


    const onDownload = () => {
        const export_list = []
        for (let s of sensors)
            if (s.checked)
                export_list.push(s.sensor_id)
        let export_sensors = {
            sensors: export_list,
            start_date: startDate.toLocaleString(),
            end_date: endDate.toLocaleString(),
        }
        if (startDate > endDate) {
            alert("Start date should before End date!")
        }
        else if (endDate < startDate) {
            alert("End date should after Start date!")
        }
        else {
            if (export_list.length > 0) {
                //console.log(export_sensors)
                axios.post(`${baseURL}/export_sensor`, export_sensors,{
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                    .then(response => {
                        //console.log(response.data)
                        setCSVData(response.data)
                        setDownloadLink(true)
                    })
            } else
                alert("Please select sensor!")
        }
    };
    let unique_pname = pname.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.process_name === value.process_name
        ))
    )
    const pnameList = unique_pname.map(p => {
        return (<option key={p.process_name} value={p.process_name}>{p.process_name}</option>);
    })
    let filtersensors;
    if (selectPname != "")
        filtersensors = sensors.filter(s => s.process_name == selectPname)
    else
        filtersensors = sensors
    const sensorList = filtersensors.map((s, index) =>
    (<Checkbox
        key={s.sensor_id}
        isChecked={s.checked}
        checkHandler={() => { setDownloadLink(false); updateCheckStatus(s.sensor_id) }}
        label={s.name + " (" + s.sensor_id + ")"}
    />)
    )
    return (<Main menuText={"Export"}>
        <center className="text-2xl font-bold">Export sensor data</center>
        <div className="mt-5 mb-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <span className="mr-3 font-medium">Start date:</span><DatePicker className="rounded-md border border-gray-300 w-25 h-10" selected={startDate} onChange={(date) => { setStartDate(date); setDownloadLink(false) }} />
            <span className="ml-3 mr-3 font-medium">End date:</span><DatePicker className="rounded-md border border-gray-300 w-25 h-10" selected={endDate} onChange={(date) => { setEndDate(date); setDownloadLink(false) }} />

        </div>
        <div className="mt-5 mb-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="cardName">Process Area:</div>
            <select style={{ "width": "120px" }} className="relative border border-gray-400 h-7" ref={pname_ref} name="process-name" id="process-name" onChange={() => { setDownloadLink(false); setSelectPname(pname_ref.current.value); /*console.log(pname_ref.current.value);*/ }}>
                <option value="">All processes</option>
                {pnameList}
            </select>
        </div>
        <center style={{ height: '500px', overflowY: 'scroll' }}>Choose sensors:{sensorList}</center>

        <center><button onClick={onDownload}
            type="button"
            className="rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 drop-shadow-md"
        >
            Export Data
        </button>{downloadlink ? <CSVLink filename={"export_data_" + new Date().toISOString() + ".csv"}
            className="ml-5 rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 drop-shadow-md"
            data={csvData}>Download Data
        </CSVLink> : <></>}</center>
    </Main>);
}