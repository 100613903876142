import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/css/style.css';
import App from './App';
import AddMachine from './AddMachine';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Dashboard from './Dashboard';
import AddSensor from './AddSensor';
import SensorPage from './SensorPage';
import MachinePage from './MachinePage';
import EditMachine from './EditMachine';
import SensorDetails from './SensorDetails';
import EditSensor from './EditSensor';
import CollectorPage from './CollectionPage';
import ExportPage from './ExportPage';
import AlarmPage from './AlarmPage';
import MachineDetails from './MachineDetails';
import Login from './Login';
import UserPage from './UserPage';
import AddUser from './AddUser';
import EditUser from './EditUser';
import Logout from './Logout';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/:p/" element={<Dashboard />} />
      <Route path="/machines" element={<MachinePage />} />
      <Route path="/machines/:machine_id" element={<MachineDetails />} />
      <Route path="/machines/add" element={<AddMachine />} />
      <Route path="/machines/edit/:machine_id" element={<EditMachine />} />
      <Route path="/sensors" element={<SensorPage />} />
      <Route path="/sensors/:sensor_id/" element={<SensorDetails />} />
      <Route path="/sensors/:sensor_id/:showAlarm" element={<SensorDetails />} />
      <Route path="/sensors/add" element={<AddSensor />} />
      <Route path="/sensors/edit/:sensor_id" element={<EditSensor />} />
      <Route path="/alarms" element={<AlarmPage />} />
      <Route path="/collectors" element={<CollectorPage />} />
      <Route path="/export" element={<ExportPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/users" element={<UserPage />} />
      <Route path="/users/add" element={<AddUser />} />
      <Route path="/users/edit/:user_id" element={<EditUser />} />
      <Route path="/logout" element={<Logout />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
