
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Main from "./Main";
import failure_dashboard from "./assets/imgs/motor_dashboard.jpg";
export default function MachineDetails() {
    const { machine_id } = useParams();

    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = sessionStorage.getItem("token");

    const [machineInfo, setMachineInfo] = useState({});
    const [attachSensor, setAttachSensor] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`${baseURL}/machines/${machine_id}`,{
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                //console.log(response.data)
                setMachineInfo(response.data);
                if (document.getElementById("machine_name"))
                    document.getElementById("machine_name").focus()
            });
     
        axios(`${baseURL}/sensors/attached/${machine_id}`,{
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                //console.log(response.data)
                setAttachSensor(response.data);
            })

    }, [])

    const attachSensorList = attachSensor.map(s => <span key={s} id='attachsensor_element'>{s}</span>);
    return (<Main menuText={"Machines"}>
        
        <form className="ml-5">
            <center className="text-2xl font-bold">Machine Details</center>
            {machine_id==1?<center><img src={failure_dashboard} width={850}/></center>:<></>}
            <fieldset className="rounded-md border border-gray-300 drop-shadow-md">
                <legend className="ml-4 text-xl font-semibold leading-7 text-gray-900">Machine Information</legend>
                <div className="mt-4 ml-4">
                    <label htmlFor="machine_name">Machine name :</label>
                    <input type="text" id="machine_name" name="machine_name" defaultValue={machineInfo.machine_name} disabled className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" />
                </div>
                <div className="mt-4 ml-4">
                    <label htmlFor="machine_type">Machine type :</label>
                    <input type="text" id="machine_type" name="machine_type" defaultValue={machineInfo.machine_type} disabled className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" />
                </div>
                <div className="mt-4 ml-4">
                    <label htmlFor="process_name">Process name :</label>
                    <input type="text" id="process_name" name="process_name" defaultValue={machineInfo.process_name} disabled className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" />
                </div>
                <div className="mt-4 ml-4">
                    <label htmlFor="site_name">Site name :</label>
                    <input type="text" id="site_name" name="site_name" defaultValue={machineInfo.site_name} disabled className="text-center ml-4 rounded-md border border-gray-300 w-80 h-10" />
                </div>
                <div className="mt-4 ml-4">
                    Machine Image:
                    {machineInfo.image_name? <>
                        <img
                            alt="Dropped image"
                            className="h-64"
                            src={baseURL + '/images/' + machine_id + '_' + machineInfo.image_name + '.jpg'}
                        />
                        {machineInfo.image_name}</> : <></>
                    }
                </div>
                <br /><div className="ml-4">Attached Sensors : {attachSensorList}</div>
                <div className="ml-4 mb-6 mt-6 flex items-center justify-left gap-x-6">
                    <button onClick={() => navigate('/machines/')}
                        type="button"
                        className="rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 drop-shadow-md"
                    >
                        Back
                    </button>
                    <button onClick={() => navigate('/machines/edit/' + machine_id)}
                        type="button"
                        className="rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 drop-shadow-md"
                    >
                        Edit Machine
                    </button>


                </div>
            </fieldset>
        </form>
    </Main>
    );
}