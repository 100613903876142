import Main from './Main'
import { Grid, html } from "gridjs";
import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "gridjs/dist/theme/mermaid.css";
import axios from 'axios';


function MachineTable() {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const token= sessionStorage.getItem("token");
    const wrapperRef = useRef(null);
    const navigate = useNavigate();
    const grid = new Grid({
        resizable: true,
        sort: true,
        search: true,
        search: {
            server: {
                url: (prev, keyword) => `${prev}?search=${keyword}`
            }
        },
        pagination: {
            limit: 10,
            server: {
                url: (prev, page, limit) => {
                    //console.log("Fetching data from APIs")
                    if (prev.includes("?search=")) {
                        return `${prev}&limit=${limit}&offset=${page * limit}`;
                    }
                    else
                        return `${prev}?limit=${limit}&offset=${page * limit}`;
                }
            }
        },
        autoWidth: true,
        columns: ['Machine id', 'Machine name', 'Machine type', 'Site name', 'Process name'],
        style: {
            table: {
                'white-space': 'nowrap',
            },
            td: {
                'background-color': 'transparent'
            },
        },
        server: {
            url: `${baseURL}/machines`,
            data: (opts)=>{
                return new Promise((resolve, reject) => {
                    axios.get(opts.url,{
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }).then(response=>{
                        console.log(response);
                        resolve({
                            data: response.data.results.map(machine => [machine.machine_id,
                                machine.machine_name,
                                machine.machine_type ? machine.machine_type : "n/a",
                                machine.site_name ? machine.site_name : "n/a",
                                machine.process_name ? machine.process_name : "n/a",
                                ]),
                            total: response.data.count,
                          }); 
                    }).catch(error=>{
                        reject(error);
                    });
                });
            },
        },
        className: {
            tr: 'gridjs-tr-class',
        }
    });
    grid.on('rowClick', (...args) => { onMachineRowClick(args[1]._cells[0].data) });
    const onMachineRowClick = (machine_id) => {
        navigate('/machines/' + machine_id);
    };

    useEffect(() => {
        grid.render(wrapperRef.current);

    }, [])
    return (<div className="ml-5" ref={wrapperRef} />)
}
export default function MachinePage() {
    const navigate = useNavigate();
    return (
        <Main menuText={"Machines"}>
            <button type="submit" onClick={() => navigate('/machines/add')}
                className="ml-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Add Machine
            </button>
            <MachineTable />
        </Main>
    );
}