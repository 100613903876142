import { useEffect } from "react";
import axios from 'axios';
import Navigation from "./Navigation";
import { SensorCardBoxes } from './SensorPage'
import { AlarmCardBoxes } from "./AlarmPage";
import { useNavigate } from "react-router-dom"
import ProcessCardBoxes from "./ProcessCardBoxes"

export default function Main({ children, menuText, p, showprocesscard, showsensorcard }) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = sessionStorage.getItem("token");
    const navigate = useNavigate();
    async function Authen() {
        try {
            const response = await axios.get(`${baseURL}/authen`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log(response.data.message);
            return true;
        } catch (error) {
            console.log("Invalid Username or Password!");
            return false;
        }
    }
    useEffect(() => {
        Authen()
        const token = sessionStorage.getItem("token");
        if (token == null || !Authen()) navigate('/login');
        let list = document.querySelectorAll(".navigation li");
        list.forEach((item) => {
            if (item.innerText == menuText)
                item.classList.add("hovered");
        });
        function activeLink() {
            list.forEach((item) => {
                item.classList.remove("hovered");
            });
            this.classList.add("hovered");
        }

        list.forEach((item) => item.addEventListener("click", activeLink));

        // Menu Toggle
        let toggle = document.querySelector(".toggle");
        let navigation = document.querySelector(".navigation");
        let main = document.querySelector(".main");

        toggle.onclick = function () {
            navigation.classList.toggle("active");
            main.classList.toggle("active");
        };
    }, [])
    return (
        <div className="container">
            <Navigation />
            <div className="main">
                <div className="topbar">
                    {<div className="toggle">
                        <ion-icon name="menu-outline"></ion-icon>
                    </div>}
                    {menuText == "Dashboard" ?
                        <div className="cardBox1">
                            <SensorCardBoxes p={p} />
                            <AlarmCardBoxes p={p} />
                            <ProcessCardBoxes p={p} />
                        </div>
                        : <></>
                    }
                    {menuText == "Sensors" ?
                        <div className="cardBox2">
                            <SensorCardBoxes p={p} />
                        </div> : <></>
                    }
                    {menuText == "Alarms" ?
                        <div className="cardBox2">
                            <AlarmCardBoxes p={p} />
                        </div> : <></>
                    }
                </div>
                {children}
            </div>
        </div>
    )
}