
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import axios from "axios";
import Modal from 'react-modal';
import Main from './Main';
import { SensorChart } from "./SensorDetails";
import BeatLoader from "react-spinners/BeatLoader";

export default function Dashboard() {

    const { p } = useParams();
    const [machines, setMachines] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(true);
    const baseURL = process.env.REACT_APP_BASE_URL;
    const interval = process.env.REACT_APP_REFRESH_INTERVAL;
    const navigate = useNavigate();

    const [modalIsOpen, setIsOpen] = useState(false);
    const [hilight, setHilight] = useState({});

    const customStyles = {
        content: {
            width: '70%',
            height: '80%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    function openModal(s) {
        setHilight(s)
        setIsOpen(true);
    }

    function afterOpenModal() {

    }

    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                let endpoint = "";
                if (p) endpoint = `${baseURL}/machines?process=${p}&search=`;
                else endpoint = `${baseURL}/machines?search=`;
                const token = sessionStorage.getItem("token");
                const response = await axios.get(endpoint, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                //console.log(response.data.results);
                setMachines(response.data.results);
                setLoading(false);
            } catch (error) {
                console.log("Cannot get machines:", error);
            }
        };

        fetchData(); // Initial fetch

        const intervalID = setInterval(() => {
            setRefresh(!refresh);
            console.log("refresh!:", new Date(Date.now()));
            //setInfowindowOpen(false);
        }, interval);

        return () => {
            clearInterval(intervalID);
        }
    }, [refresh, p]);

    const MachineList = machines.map(machine => <div key={machine.machine_id} onClick={ ()=>navigate(`/machines/${machine.machine_id}`)}>
        <span><b>Machine name:</b> {machine.machine_name}</span>
        {machine.image_name ? <img src={baseURL + '/images/' + machine.machine_id + '_' + machine.image_name + '.jpg'} /> : <ion-icon name="eye-off-outline"></ion-icon>}
        <hr />
        {machine.sensors.map(s => <div onClick={(e) => { e.stopPropagation();  openModal(s); }} key={s.sensors_sensor_id}><span className='tooltip'>
            {s.alarm == "normal" ? <ion-icon name="ellipse" style={{ color: "#8de02c" }}></ion-icon> : <></>}
            {s.alarm == "warning" ? <ion-icon name="ellipse" style={{ color: "#e9b10a" }}></ion-icon> : <></>}
            {s.alarm == "critical" ? <ion-icon name="ellipse" style={{ color: "#f00" }}></ion-icon> : <></>}
            {s.alarm == "not working" ? <ion-icon name="ellipse" style={{ color: "#6c6c6c" }}></ion-icon> : <></>}
            {s.alarm == "unreachable" ? <ion-icon name="flash-off" style={{ color: "#6c6c6c" }}></ion-icon> : <></>}
            {s.sensors_sensor_id}<span className="tooltiptext">{s.db_time}:{s.alarm}</span></span>
            <hr />
        </div>)}
        <hr />
    </div>);
    return (
        <Main menuText={"Dashboard"} p={p}>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Sensor data">
                <div className="flex justify-end">
                    <button className="rounded-md bg-red-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 drop-shadow-md" onClick={closeModal}>close</button>
                </div>
                <SensorChart sensor_id={hilight.sensors_sensor_id} name={hilight.name} />
                <span className="ml-5 rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 drop-shadow-md" onClick={() => { navigate(`/sensors/${hilight.sensors_sensor_id}`) }}>See more...</span>
            </Modal>
            {loading ?
                <center className='mt-40'>
                    <BeatLoader
                        color={"#000000"}
                        loading={loading}
                        size={10}
                        aria-label="Loading Spinner"
                        data-testid="loader" />
                </center> :
                <div className="machine-container">
                    {MachineList}
                </div>
            }
        </Main>);
}