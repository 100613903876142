import Main from './Main'
import { Grid, html } from "gridjs";
import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "gridjs/dist/theme/mermaid.css";
import axios from "axios";

function CollectorTable() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("token");
  const wrapperRef = useRef(null);
  const navigate = useNavigate();
  const grid = new Grid({
    resizable: true,
    sort: true,
    search: true,
    search: {
      server: {
        url: (prev, keyword) => `${prev}?search=${keyword}`
      }
    },
    pagination: {
      limit: 25,
      server: {
        url: (prev, page, limit) => {
          //console.log("Fetching data from APIs")
          if (prev.includes("?search=")) {
            return `${prev}&limit=${limit}&offset=${page * limit}`
          }
          else
            return `${prev}?limit=${limit}&offset=${page * limit}`
        }
      }
    },
    autoWidth: true,
    columns: ['collector id', 'collector name', 'protocol', 'uri', 'username', 'password', 'port', 'prefix', 'postfix', 'interval', 'token'],
    style: {
      table: {
        'white-space': 'nowrap',
      },
      td: {
        'background-color': 'transparent'
      },
    },
    server: {
      url: `${baseURL}/collectors`,
      data: (opts) => {
        return new Promise((resolve, reject) => {
          axios.get(opts.url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then(response => {
            //console.log(response);
            resolve({
              data: response.data.results.map(collector => [collector.collector_id,
                collector.collector_name ? collector.collector_name : "n/a",
                collector.protocol_type,
                collector.uri,
                collector.username ? collector.username : "n/a",
                collector.password ? collector.password : "n/a",
                collector.port ? collector.port : "n/a",
                collector.prefix ? collector.prefix : "n/a",
                collector.postfix ? collector.postfix : "n/a",
                collector.interval ? collector.interval : "n/a",
                collector.token ? collector.token : "n/a",
                ]),
              total: response.data.count,
            });
          }).catch(error => {
            reject(error);
          });
        });
      },
    },
    className: {
      tr: 'gridjs-tr-class',
    }
  });


  useEffect(() => {
    grid.render(wrapperRef.current);

  }, [])
  return (<div className="ml-5" ref={wrapperRef} />)
}
export default function CollectorPage() {
  return (
    <Main menuText={"Collectors"}>
      <CollectorTable />
    </Main>
  );
}